import React, {useRef, useState} from 'react';
import {toast} from 'react-toastify';
import {login} from '../../services';
import {Mokojam, MokojamLogo} from '../../assets';
import {useLocalizedNavigation} from 'hooks';
import {useIntl} from 'react-intl';
import {LocalizedNavLink, LocalizedText} from 'components';

export const LoginPage: React.FC = () => {
  const intl = useIntl();
  const navigate = useLocalizedNavigation();
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const submit = async () => {
    try {
      const {userSession} = await login({email, password});
      localStorage.setItem('userSession', JSON.stringify(userSession));
      navigate('/');
    } catch (e: any) {
      localStorage.clear();
      setPassword('');
      setEmail('');
      if (emailRef.current) {
        // @ts-ignore
        emailRef.current.focus();
      }
      if (e.status && e.status === 401) {
        toast.error(intl.formatMessage({id: 'toast.login.invalid'}), {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      } else {
        toast.error(intl.formatMessage({id: 'toast.common.fail'}), {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      }
    }
  };

  return (
    <div className="relative flex bg-black justify-center items-center min-h-screen overflow-hidden">
      <div className="mb-16 flex flex-col justify-between items-center w-full max-w-[600px] p-2 sm:p-6 gap-8">
        <div className="flex justify-center items-center w-full">
          <Mokojam className="h-[2.5em] hidden sm:block" iswhite={false} />
          <MokojamLogo className="h-[2.5em] block sm:hidden" iswhite={false} />
        </div>
        <div className="w-full p-6 m-auto bg-white border-solid border border-black rounded-md lg:max-w-xl">
          <h1 className="text-2xl font-semibold text-center text-grey-700">
            <LocalizedText id="pages.login.signIn" />
          </h1>
          <form className="mt-6">
            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-semibold text-gray-800">
                <LocalizedText id="common.email" />
                <input
                  ref={emailRef}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                  onKeyDown={async (event: any) => {
                    if (event.key === 'Enter') {
                      if (!!email.length && !password.length) {
                        if (passwordRef.current) {
                          // @ts-ignore
                          passwordRef.current.focus();
                        }
                      }
                      if (!!email.length && !!password.length) {
                        await submit();
                      }
                    }
                  }}
                  id="email"
                  type="email"
                  className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-grey-400 focus:ring-grey-300 focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </label>
            </div>
            <div className="mb-2">
              <label htmlFor="password" className="block text-sm font-semibold text-gray-800">
                <div className="flex justify-between flex-wrap">
                  <span>
                    <LocalizedText id="common.password" />
                  </span>
                  <LocalizedNavLink className="text-gray-500 text-xs flex text-start my-auto" to="/forgot/password">
                    <LocalizedText id="pages.login.forgotPassword" />
                  </LocalizedNavLink>
                </div>
                <input
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setPassword(e.target.value);
                  }}
                  ref={passwordRef}
                  value={password}
                  onKeyDown={async (event: any) => {
                    if (event.key === 'Enter') {
                      if (!!password.length && !email.length) {
                        if (emailRef.current) {
                          // @ts-ignore
                          emailRef.current.focus();
                        }
                      }
                      if (!!email.length && !!password.length) {
                        await submit();
                      }
                    }
                  }}
                  id="password"
                  type="password"
                  className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-grey-400 focus:ring-grey-300 focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </label>
            </div>
            <div className="mt-6">
              <button
                onClick={submit}
                type="button"
                className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-black rounded-md hover:bg-opacity-70 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 gap-3"
              >
                <LocalizedText id="common.login" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
