import React from 'react';
import {io, Socket} from 'socket.io-client';

export type UseSocketConnectionProps = {
  handleConnect?(socket?: Socket): void;
  handlers?: {event: string; handleFn(data: any, socket: Socket): void}[];
  enabled?: boolean;
  query?: Record<string, any>;
  namespace?: string;
};

const getSocket = (namespace: string, query?: Record<string, any>) => io(`${process.env.REACT_APP_TOOLBOX_HOST}/${namespace}`, {query, autoConnect: false});

export const useSocketConnection = (props: UseSocketConnectionProps) => {
  const {handleConnect, handlers = [], enabled = true, query, namespace = ''} = props;

  const socketRef = React.useRef<Socket | undefined>();
  React.useEffect(() => {
    socketRef.current = getSocket(namespace, query);
    if (handleConnect) {
      socketRef.current.on('connect', () => {
        handleConnect(socketRef.current);
      });
    }
  }, []);

  React.useEffect(() => {
    const socket = socketRef.current;
    if (!socket) {
      return;
    }
    handlers.forEach(({event, handleFn}) => {
      const handlerFn = (data: any) => handleFn(data, socket);
      socket.removeAllListeners(event);
      socket.on(event, handlerFn);
    });
  }, [handlers]);

  React.useEffect(() => {
    const socket = socketRef.current;
    if (!enabled || !socket) {
      return () => {};
    }

    socket.connect();

    return () => {
      socket.disconnect();
    };
  }, [enabled]);

  return socketRef.current;
};
