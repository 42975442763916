import React from 'react';

import {Footer, Header} from 'layout';
import {Outlet} from 'react-router-dom';

const PageLayoutComponent: React.FC = () => {
  return (
    <div className="flex flex-col h-full">
      <Header />
      <div className="grow h-full overflow-auto">
        <div id="top-anchor" aria-label="hidden" className="pointer-events-none" />
        <main className="h-full">
          <Outlet />
        </main>
      </div>
      <Footer />
    </div>
  );
};
export const PageLayout = React.memo(PageLayoutComponent);
