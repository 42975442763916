import React, {useState} from 'react';
import {Button, LoadingComponent, LocalizedText, Modal} from 'components';
import {useWorkshopAvailableUsers} from 'services/query';
import {Select} from 'antd';

interface AddUserModalProps {
  workshopId: number;
  isOpen: boolean;
  onClose: () => void;
  handleAddWorkshopUsers?: (data: {userIds: number[]; access: ToolboxObject.WorkshopUserAccess}) => void;
}

export const AddUserModal: React.FC<AddUserModalProps> = ({workshopId, isOpen, onClose, handleAddWorkshopUsers}) => {
  const query = useWorkshopAvailableUsers(workshopId, {enabled: isOpen});
  const [userIds, setUserIds] = useState<number[]>([]);
  const [access, setAccess] = useState<ToolboxObject.WorkshopUserAccess>('read');

  React.useEffect(() => {
    if (query.isSuccess) {
      setUserIds([]);
    }
  }, [query.isSuccess]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="sm:w-[500px] sm:h-fit sm:mx-auto">
      <div className="h-full flex gap-6 flex-col">
        <h5 className="font-bold text-xl">
          <LocalizedText id="pages.workshop.shareWorkshop" />
        </h5>

        <div className="flex flex-col gap-2">
          <p className="text-sm">
            <LocalizedText id="pages.workshop.user" />
          </p>
          <LoadingComponent query={query}>
            {() => {
              return (
                <Select value={userIds} onChange={setUserIds} className="w-full" mode="multiple">
                  {query.data?.map((user) => (
                    <Select.Option key={user.id} value={user.id}>
                      {user.first_name} {user.last_name}
                    </Select.Option>
                  ))}
                </Select>
              );
            }}
          </LoadingComponent>
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-sm">
            <LocalizedText id="pages.workshop.access" />
          </p>
          <Select placeholder="User" value={access} onChange={setAccess} className="w-full">
            <Select.Option value="read">
              <LocalizedText id="pages.workshop.access.readOnly" />
            </Select.Option>
            <Select.Option value="full">
              <LocalizedText id="pages.workshop.access.full" />
            </Select.Option>
          </Select>
        </div>
        <div className="h-fit flex gap-2 flex-row-reverse w-full">
          <Button
            disabled={!userIds.length || !access}
            onClick={() => {
              if (userIds.length && access) {
                handleAddWorkshopUsers?.({userIds, access});
                onClose();
              }
            }}
          >
            <LocalizedText id="common.share" />
          </Button>
          <Button
            onClick={() => {
              onClose();
            }}
            className="bg-white !text-black border !border-black hover:bg-gray-50"
          >
            <LocalizedText id="common.cancel" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};
