import {useLayoutEffect} from 'react';
import {useLocation} from 'react-router-dom';

export const ScrollToTop: React.FC = () => {
  const location = useLocation();

  useLayoutEffect(() => {
    document.getElementById('top-anchor')?.scrollIntoView();
  }, [location.pathname]);

  return null;
};
