import React, {useMemo} from 'react';

import {useDocumentTitle} from 'hooks';
import {HomeTile} from '../../components/HomeTile';
import {ArrowRight, ShoppingBag, shopTeaser, startFromScratch, startFromTemplate} from '../../assets';
import {LocalizedNavLink, LocalizedText, MethodLibraryTile, RecipeLibraryTile} from '../../components';
import {LoadingComponent, useMethods, useRecipes, useSelf, useWorkshops} from '../../services/query';
import {WorkshopLibraryTile} from '../../components/LibraryTile';

interface RandomRecipeProps {
  recipes: ToolboxObject.Workshop[];
}

export const RandomRecipe: React.FC<RandomRecipeProps> = ({recipes}) => {
  if (recipes?.length) {
    return <RecipeLibraryTile recipe={recipes[Math.floor(Math.random() * recipes.length)]} />;
  }
  return null;
};

interface RandomMethodProps {
  methods: ToolboxObject.Method[];
}

const RandomMethod: React.FC<RandomMethodProps> = ({methods}) => {
  if (methods?.length) {
    return <MethodLibraryTile method={methods[Math.floor(Math.random() * methods.length)]} />;
  }
  return null;
};

const sortRecent = (a: ToolboxObject.Method | ToolboxObject.Workshop, b: ToolboxObject.Method | ToolboxObject.Workshop) => {
  if (a.user_data?.last_viewed === b.user_data?.last_viewed) {
    return 0;
  }
  if (!a.user_data || !a.user_data.last_viewed) {
    return 1;
  }
  if (!b.user_data || !b.user_data.last_viewed) {
    return -1;
  }
  if (a.user_data.last_viewed < b.user_data.last_viewed) {
    return 1;
  }
  return -1;
};

export const RootPage: React.FC = () => {
  useDocumentTitle('root');

  const self = useSelf();
  const methods = useMethods();
  const recipes = useRecipes();
  const workshops = useWorkshops();
  const recentMethods = useMemo(() => {
    if (!methods.data) {
      return [];
    }
    return [...methods.data].sort(sortRecent).slice(0, 3);
  }, [methods.data]);

  const recentRecipes = useMemo(() => {
    if (!recipes.data) {
      return [];
    }
    return [...recipes.data].sort(sortRecent).slice(0, 3);
  }, [recipes.data]);

  const recentWorkshops = useMemo(() => {
    if (!workshops.data) {
      return [];
    }
    return [...workshops.data].sort(sortRecent).slice(0, 3);
  }, [workshops.data]);

  return (
    <div>
      <div className="m-auto flex flex-col items-center">
        <div className="w-full bg-black pt-8">
          <div className="w-full m-auto pb-14 px-4 sm:px-24 max-w-[1600px]">
            <div className="flex flex-col gap-8 items-start">
              <div className="flex flex-col gap-4">
                <div className="flex items-center whitespace-pre text-white text-xl leading-8 font-medium">
                  <LoadingComponent query={self} pulse>
                    {() => {
                      return (
                        <p>
                          <LocalizedText id="pages.home.welcomeHeader" values={{name: self.data?.first_name || self.data?.email}} />
                        </p>
                      );
                    }}
                  </LoadingComponent>
                </div>
                <p className="text-white text-2xl font-extrabold ">
                  <LocalizedText id="pages.home.welcomeMessage" />
                </p>
              </div>
              <div className="flex flex-row w-full justify-center flex-wrap gap-4">
                <div className="flex flex-1 w-full flex-wrap justify-center gap-6">
                  <HomeTile image={startFromScratch} textKey="pages.home.startFromScratch" nav="/editor/new" />
                  <HomeTile image={startFromTemplate} textKey="pages.home.startFromTemplate" nav="/library/recipes" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-12 w-full px-4 pt-14 pb-32 sm:px-24 max-w-[1600px]">
          <LoadingComponent query={methods}>
            {() => {
              return (
                <>
                  {!!workshops.data?.length && (
                    <div className="flex flex-col gap-6">
                      <div className="flex justify-between px-2 py-1 w-full bg-gray-100  rounded-md">
                        <p className="text-base font-bold">
                          <LocalizedText id="pages.home.recentWorks" />
                        </p>
                        <LocalizedNavLink to="/workshops">
                          <div className="flex items-center justify-center gap-3 cursor-pointer">
                            <p className="text-base font-bold">
                              <LocalizedText id="pages.home.showAll" />
                            </p>
                            <ArrowRight />
                          </div>
                        </LocalizedNavLink>
                      </div>
                      <div className="grid gap-5 grid-cols-1 xl:grid-cols-3">
                        {!!recentWorkshops.length &&
                          recentWorkshops.map((recentWorkshop) => {
                            return <WorkshopLibraryTile workshop={recentWorkshop} key={recentWorkshop.id} />;
                          })}
                      </div>
                    </div>
                  )}
                  <div className="flex flex-col gap-6">
                    <div className="flex justify-between px-2 py-1 w-full bg-gray-100  rounded-md">
                      <p className="text-base font-bold">
                        <LocalizedText id="pages.home.recentRecipes" />
                      </p>
                      <LocalizedNavLink to="/library/recipes">
                        <div className="flex items-center justify-center gap-3 cursor-pointer">
                          <p className="text-base font-bold">
                            <LocalizedText id="pages.home.showAll" />
                          </p>
                          <ArrowRight />
                        </div>
                      </LocalizedNavLink>
                    </div>
                    <div className="grid sm:auto-rows-[minmax(0,200px)] md:auto-rows-[minmax(0,156px)] lg:auto-rows-[minmax(0,300px)] xl:auto-rows-[minmax(0,226px)] 2xl:auto-rows-[minmax(0,180px)] gap-6 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3">
                      {recentRecipes.length ? (
                        recentRecipes.map((recentRecipe) => {
                          return <RecipeLibraryTile key={recentRecipe.id} recipe={recentRecipe} />;
                        })
                      ) : recipes.data?.length ? (
                        <>
                          <RandomRecipe recipes={recipes.data} />
                          <RandomRecipe recipes={recipes.data} />
                          <RandomRecipe recipes={recipes.data} />
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex flex-col gap-6">
                    <div className="flex justify-between px-2 py-1 w-full bg-gray-100  rounded-md">
                      <p className="text-base font-bold">
                        <LocalizedText id="pages.home.recentMethods" />
                      </p>
                      <LocalizedNavLink to="/library/methods">
                        <div className="flex items-center justify-center gap-3 cursor-pointer">
                          <p className="text-base font-bold">
                            <LocalizedText id="pages.home.showAll" />
                          </p>
                          <ArrowRight />
                        </div>
                      </LocalizedNavLink>
                    </div>
                    <div className="grid gap-5 grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3">
                      {recentMethods.length ? (
                        recentMethods.map((recentMethod) => {
                          return <MethodLibraryTile key={recentMethod.id} method={recentMethod} />;
                        })
                      ) : methods.data?.length ? (
                        <>
                          <RandomMethod methods={methods.data || []} />
                          <RandomMethod methods={methods.data || []} />
                          <RandomMethod methods={methods.data || []} />
                        </>
                      ) : null}
                    </div>
                  </div>
                </>
              );
            }}
          </LoadingComponent>
        </div>
        <div className="w-full bg-black">
          <div className="flex flex-col m-auto gap-9 w-full px-4 pt-14 pb-16 sm:px-24 max-w-[1600px]">
            <div className="relative isolate overflow-hidden bg-white px-6 pt-16 rounded-xl sm:px-16 md:pt-20 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
              <div className="mx-auto lg:max-w-[75%] text-center lg:mx-0 lg:flex-auto lg:py-20 lg:text-left">
                <h2 className="text-3xl font-['Inter'] font-bold tracking-tight text-black xl:text-4xl">
                  <LocalizedText id="pages.home.upgradeWorkshops.header" values={{br: <br />}} />
                </h2>
                <p className="mt-6 text-sm xl:text-lg font-['Inter'] text-gray-400">
                  <LocalizedText id="pages.home.upgradeWorkshops.body" />
                </p>
                <div className="mt-10 justify-center gap-x-6 lg:justify-start inline-flex items-center rounded-md border border-transparent bg-black px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-opacity-70 focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 gap-3">
                  <a href="https://mokojam.com/collections/all" target="_blank" rel="noreferrer" className="inline-flex gap-1.5 items-center justify-center">
                    <ShoppingBag stroke="white" />
                    <LocalizedText id="pages.home.upgradeWorkshops.buttonText" />
                  </a>
                </div>
              </div>
              <div className="relative mt-16 h-100 lg:my-8 w-full">
                <img
                  className="m-auto md:px-16 md:py-4 lg:px-0 pt-8 pb-0 lg:absolute lg:top-0 lg:left-0 lg:w-[21rem] lg:max-w-none"
                  src={shopTeaser}
                  alt="Shop Teaser"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
