import React, {useMemo} from 'react';
import Fuse from 'fuse.js';
import {MethodStatus} from 'types';
import {Collapsible, MethodLibraryTile} from '../../components';
import {LoadingComponent, useMethods} from '../../services/query';
import {filterMethods, groupMethodsByCategory, sortMethodCategories} from '../../services';
import {MethodFilters} from './filters';
import {useIntl} from 'react-intl';
import {getPlainStringFromHTML} from 'utils';
import {translateMethod, useLocale} from 'hooks';

interface MethodLibraryProps {
  query?: string | undefined;
  handleMethodClick?(id: number): void;
  statuses?: MethodStatus[];
  showOthers?: boolean;
}

export const MethodLibrary: React.FC<MethodLibraryProps> = ({query = '', handleMethodClick = undefined, statuses, showOthers = false}) => {
  const intl = useIntl();
  const locale = useLocale();

  const methods = useMethods(statuses);
  const {data} = methods;
  const [filters, setFilters] = React.useState<ToolboxObject.MethodFilterData>({});
  const methodsByCategory = useMemo(() => {
    const filteredData = filterMethods(filters, data);
    return groupMethodsByCategory(
      query
        ? new Fuse(filteredData, {keys: ['title', 'teaser'], includeScore: true, threshold: 0.5}).search(query).map((result) => {
            return result.item;
          })
        : filteredData,
      showOthers
    );
  }, [data, query, filters]);

  return (
    <LoadingComponent query={methods}>
      {() => {
        return (
          <div className="flex flex-col gap-y-12">
            <MethodFilters applyFilters={setFilters} />
            <div className="flex flex-col gap-4">
              {Object.entries(methodsByCategory)
                .sort(sortMethodCategories)
                .map(([category, categoryMethods]) => {
                  const key = query ? crypto.randomUUID() : category;
                  const categoryKey = category.split(' ').join('_').toLowerCase();
                  const title = intl.formatMessage({id: `pages.library.category.${categoryKey}`, defaultMessage: category});
                  return (
                    <Collapsible title={title} key={key}>
                      <div className="grid gap-6 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 mb-12 mt-6">
                        {categoryMethods
                          .map((method: ToolboxObject.Method) => translateMethod(locale, method))
                          .sort((method1: ToolboxObject.Method, method2: ToolboxObject.Method) =>
                            getPlainStringFromHTML(method1.title.toLowerCase()).trim().localeCompare(getPlainStringFromHTML(method2.title.toLowerCase()).trim())
                          )
                          .map((method: ToolboxObject.Method) => {
                            return <MethodLibraryTile method={method} key={method.id} handleClick={handleMethodClick} />;
                          })}
                      </div>
                    </Collapsible>
                  );
                })}
            </div>
          </div>
        );
      }}
    </LoadingComponent>
  );
};
