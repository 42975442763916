import React from 'react';
import {Socket} from 'socket.io-client';

type UseLiveWorkshopUpdatesProps = {
  socket?: Socket;
  enabled?: boolean;
  currentWorkshopState: ToolboxObject.WorkshopEditableState;
};

export const useLiveWorkshopUpdates = (props: UseLiveWorkshopUpdatesProps) => {
  const {socket, enabled = true, currentWorkshopState} = props;

  React.useEffect(() => {
    if (!enabled) {
      return;
    }
    socket?.emit('state-sync', {state: currentWorkshopState});
  }, [enabled, JSON.stringify(currentWorkshopState)]);
};
